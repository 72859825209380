import { Injectable } from '@angular/core';
import { COMMON, MENUPUNKTE } from '../constants';
import { DataService } from '../data/data.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isNil } from 'lodash-es';

@Injectable()
export class LoggingService {
  private serviceBase: string;

  /**
   * Stores the menupoint of the last section which was visited by the user
   */
  private menupunktOfLastRoute: number | null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dataService: DataService) {
    this.serviceBase = COMMON.API_ROOT + '/log';
    this.menupunktOfLastRoute = null;
  }

  /**
   * Subscribes to the router events
   */
  init(): void {
    this.subscribeOnRouterEvents();
  }

  /**
   * Posts the menupoint the user has visited
   */
  private postMenupunkt(menupunkt: number) {
    return this.dataService.postData(this.serviceBase + '/menuepunkt/' + this.parseMenupunkt(menupunkt));
  }

  /**
   * Will be call when a new route was entered.
   * Determines the current menupoint and if it changes the section will be logged.
   * @param event {@link NavigationEnd}
   */
  private onNavigationEnd(event: NavigationEnd) {
    const lastActivatedRoute = this.determineActivatedRouteOfLastChild();
    const menupunkt = this.findMenupunkt(lastActivatedRoute.snapshot);
    const noLogging = this.findNoLogging(lastActivatedRoute.snapshot);

    if (!noLogging && !isNil(menupunkt) && menupunkt !== this.menupunktOfLastRoute) {
      this.postMenupunkt(menupunkt).subscribe();
      this.menupunktOfLastRoute = menupunkt;
    }
  }

  /**
   * Subscribes to the router events stream and filters out the `onNavigationEnd` event.
   * If this is the case `onNavigationEnd` function will be called.
   */
  private subscribeOnRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event) => {
          if (event instanceof NavigationEnd) {
            return true;
          }
          return false;
        })
      )
      .subscribe({
        next: (event: NavigationEnd) => this.onNavigationEnd(event),
      });
  }

  /**
   * Determines the last element of the ActivatedRoute trees, corresponds to the current route.
   */
  private determineActivatedRouteOfLastChild(): ActivatedRoute {
    let currentActivatedRoute = this.activatedRoute;
    while (!isNil(currentActivatedRoute.firstChild)) {
      currentActivatedRoute = currentActivatedRoute.firstChild;
    }
    return currentActivatedRoute;
  }

  /**
   * Determines the menu point from the given route snapshot.
   * The parent snapshots will also be considered.
   * @param snapshot the {@link ActivatedRoute}, which corresponds to the current route
   */
  private findMenupunkt(snapshot: ActivatedRouteSnapshot): number | null {
    let menupunkt: number | null;
    let currentRoute: ActivatedRouteSnapshot = snapshot;

    if (currentRoute.routeConfig && snapshot.routeConfig.data && !isNil(snapshot.routeConfig.data.menupunkt)) {
      menupunkt = snapshot.routeConfig.data.menupunkt;
    } else {
      menupunkt = null;
    }

    while (isNil(menupunkt) && currentRoute.parent) {
      currentRoute = currentRoute.parent;
      if (
        currentRoute.routeConfig &&
        currentRoute.routeConfig.data &&
        !isNil(currentRoute.routeConfig.data.menupunkt)
      ) {
        menupunkt = currentRoute.routeConfig.data.menupunkt;
      }
    }
    return menupunkt;
  }

  /**
   * Determines the "noLogging" attribute of the current snapshot.
   * The parent snapshots will also be considered.
   * @param snapshot the {@link ActivatedRoute}, which corresponds to the current route
   */
  private findNoLogging(snapshot: ActivatedRouteSnapshot): boolean {
    let noLogging: boolean;
    let currentRoute: ActivatedRouteSnapshot = snapshot;

    if (currentRoute.routeConfig && snapshot.routeConfig.data && !isNil(snapshot.routeConfig.data.noLogging)) {
      noLogging = snapshot.routeConfig.data.noLogging;
    } else {
      noLogging = false;
    }

    while (isNil(noLogging) && currentRoute.parent) {
      currentRoute = currentRoute.parent;
      if (
        currentRoute.routeConfig &&
        currentRoute.routeConfig.data &&
        !isNil(currentRoute.routeConfig.data.noLogging)
      ) {
        noLogging = currentRoute.routeConfig.data.noLogging;
      }
    }
    return noLogging;
  }

  /**
   * Transforms the menu point into associated string.
   * If no case for the menu point is found, the menupoint itself will be returned.
   * @param menupunkt the menu point of the selected section
   */
  private parseMenupunkt(menupunkt: number): string | number {
    let uebersetzung = '';

    switch (menupunkt) {
    case MENUPUNKTE.STARTSEITE:
      uebersetzung += 'Startseite';
      break;
    case MENUPUNKTE.SALMO.SALMO:
      uebersetzung += 'Salmonellenmonitoring';
      break;
    case MENUPUNKTE.SALMO.TGI:
      uebersetzung += 'TGI';
      break;
    case MENUPUNKTE.QS.QS:
      uebersetzung += 'Qualitaetssicherung';
      break;
    case MENUPUNKTE.QS.AUDITING:
      uebersetzung += 'QS_Auditierung';
      break;
    case MENUPUNKTE.QS.REMEDYING_DEFECTS:
      uebersetzung += 'Maengelbeseitigung';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.SCHLACHTDATEN:
      uebersetzung += 'Schlachtdaten';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.LIEFERPARTIEN:
      uebersetzung += 'Lieferpartien';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.WIEGELISTE:
      uebersetzung += 'Wiegeliste';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.BASISVERGLEICH:
      uebersetzung += 'Basisvergleich';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.AGGREGATION:
      uebersetzung += 'Aggregation';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.MASKENBETRACHTUNG:
      uebersetzung += 'Maskenbetrachtung';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.LIEFERZEITRAUM:
      uebersetzung += 'Lieferzeitraum';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.DATENDOWNLOAD:
      uebersetzung += 'Datendownload';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.BETRIEBSVERGLEICH:
      uebersetzung += 'Betriebsvergleich';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.MASKENVERGLEICH:
      uebersetzung += 'Maskenvergleich';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.LANDWIRT_PLUS_WERBESEITE:
      uebersetzung += 'Landwirt PLUS Werbeseite';
      break;
    case MENUPUNKTE.SCHLACHTDATEN.BEFUNDDATEN:
      uebersetzung += 'Befunddaten';
      break;
    case MENUPUNKTE.ETM.ETM:
      uebersetzung += 'Mastauswertung';
      break;
    case MENUPUNKTE.ETM.BASISVERGLEICH:
      uebersetzung += 'ETM-Basisvergleich';
      break;
    case MENUPUNKTE.ETM.EINZELTIERLISTE:
      uebersetzung += 'ETM-Einzeltierliste';
      break;
    case MENUPUNKTE.ETZ.ETZ:
      uebersetzung += 'Zuchtauswertung';
      break;
    case MENUPUNKTE.ETZ.BASISVERGLEICH:
      uebersetzung += 'ETZ-Basisvergleich';
      break;
    case MENUPUNKTE.ETZ.RANGIERUNG:
      uebersetzung += 'ETZ-Rangierung';
      break;
    case MENUPUNKTE.ETZ.EINZELTIERLISTE:
      uebersetzung += 'ETZ-Einzeltierliste';
      break;
    case MENUPUNKTE.BETRIEBSSTAETTEN:
      uebersetzung += 'Betriebsstaetten';
      break;
    case MENUPUNKTE.ANTIBIOTIKA.MONITORING:
      uebersetzung += 'Antibiotikamonitoring';
      break;
    case MENUPUNKTE.ANTIBIOTIKA.ZERO_REPORTS:
      uebersetzung += 'Nullmeldung';
      break;
    case MENUPUNKTE.UNTERBERATER:
      uebersetzung += 'Unterberater';
      break;
    case MENUPUNKTE.QS_MANAGEMENT.BETRIEBSLISTE:
      uebersetzung += 'Betriebsliste';
      break;
    case MENUPUNKTE.QS_MANAGEMENT.MAENGELLISTE:
      uebersetzung += 'Maengelliste';
      break;
    case MENUPUNKTE.ITW:
      uebersetzung += 'Initiative-Tierwohl';
      break;
    case MENUPUNKTE.BLACKBOX:
      uebersetzung += 'Black Box';
      break;
    case MENUPUNKTE.MASTGRUPPEN:
      uebersetzung += 'Mastgruppen';
      break;
    case MENUPUNKTE.BENACHRICHTIGUNGEN:
      uebersetzung += 'Benachrichtigungen';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.PARTNERSCHAFT:
      uebersetzung += 'Partnerschaft';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.EXPERTEN_NETZWERK:
      uebersetzung += 'Experten-Netzwerk';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.MARKTBERICHT:
      uebersetzung += 'Marktbericht';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.VERTRAGSVERWALTUNG:
      uebersetzung += 'Vertragsverwaltung';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.TREUEBONUS:
      uebersetzung += 'Treuebonus';
      break;
    case MENUPUNKTE.PARTNERSCHAFT.STAMMDATENVERWALTUNG:
      uebersetzung += 'Stammdatenverwaltung';
      break;
    case MENUPUNKTE.BR:
      uebersetzung += 'Bestandregister';
      break;
    default:
      uebersetzung += menupunkt;
    }

    return uebersetzung;
  }
}
