import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BUILD_TIMESTAMP } from './environments/build-info';

Sentry.init({
  dsn: 'https://561d018fe73f4780b440c6d091e4a9bb@sentry.iq-agrar.de/7',
  allowUrls: [/https?:\/\/((www)\.)?portal\.((develop|staging)\.)?iq-agrar\.de/],
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
  environment: environment.production ? 'production' : 'development',
  // release: environment.toennies ? 'Toennies' : 'IQ-Agrar',
  release: (environment.toennies ? 'Toennies' : 'IQ-Agrar') + '@' + BUILD_TIMESTAMP,
  beforeSend: (sentryEvent): Sentry.ErrorEvent | null => {
    if (sentryEvent?.request?.url?.includes('staging')) {
      sentryEvent.environment = 'staging';
    }
    return sentryEvent;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeSendTransaction: (transaction: any): any => {
    if (transaction?.request?.url?.includes('localhost')) {
      return null;
    }
    return transaction;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
